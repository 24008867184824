import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 72px;
  display: flex;
  left: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(rgba(246, 246, 255, 0.3), transparent);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  backdrop-filter: blur(17px);
  position: fixed;
  top: ${(props) => (props.isScrolled ? '0px' : '50px')};
  transition: top 0.3s ease-in-out;
`;

export const NavLinks = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  a {
    color: #010043;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    padding: 8px 24px;
    background: rgba(1, 0, 67, 0.08);
    border-radius: 8px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    height: 24px;

    &:hover {
      background: rgba(1, 0, 67, 0.12);
      color: #010043;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
  color: #010043;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DrawerContainer = styled.div`
  position: fixed;
  top: 72px;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 85%;
  max-width: 320px;
  background: white;
  box-shadow: -4px 0 24px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  border-radius: 16px 0 0 16px;
  margin: 8px 16px;
`;

export const DrawerHeader = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid rgba(1, 0, 67, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DrawerTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #010043;
`;

export const DrawerNavLinks = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    color: #010043;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    padding: 12px 16px;
    background: rgba(1, 0, 67, 0.04);
    border-radius: 12px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: rgba(1, 0, 67, 0.08);
      color: #010043;
      transform: translateX(4px);
    }

    &:active {
      transform: scale(0.98);
    }
  }
`;

export const DrawerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transition: all 0.3s ease-in-out;
  z-index: 999;
  backdrop-filter: blur(4px);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #010043;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(1, 0, 67, 0.08);
  }
`;

export const MasterLayoutStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const MasterComponentStyle = styled.div`
  margin: 24px;
  background: var(--surface-medium, #EBF4FF);
  width: 100%;
`;