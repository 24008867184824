import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "./Sidebar";
import { MasterComponentStyle, MasterLayoutStyle } from "../Home/Header/style";
import FindProfile from "./FindProfile";
import LikedProfile from "./LikedProfile";
import MyCreators from "./MyCreators";
import axios from "axios";
import apiClient from "../../services/axiosInstance";
import { notification } from "antd";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { getMyCreators } from "../../services/creatorApi";
import { CustomDrawer } from "./CustomDrawer";
import Analytics from "../../pages/Analytics";

const DashboardBrand = () => {
    const [defaultSelectedKey, setDefaultSelectedKey] = useState('findProfile');
    const [categoryList, setCategoryList] = useState([])
    const [categoryCount, setCategoryCount] = useState({ real: 0, scraped: 0 })
    const [categorySelected, setCategorySelected] = useState([])
    const [sortBy,setSortBy] = useState('total_follower_count')
    const [location,setLocation] =useState([])
    const [minFollowers,setMinFollowers] = useState(null)
    const [maxFollowers,setMaxFollowers] = useState(null)
    const [minAge,setMinAge] = useState(13)
    const [maxAge,setMaxAge] = useState(100)
    const [gender, setGender] = useState(["All"])
    const [page,setPage]= useState(1)
    const [creatorsList,setCreatorsList] = useState([])
    const [hasMore,setHasMore] = useState(false)
    const [loadingCreators, setLoadingCreators]= useState(false)
    const [likedUserUuid, setLikedUserUuid] = useState([])
    const [platformType, setPlatformType]= useState("");
    const observer = useRef()
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const [myCreatorsList, setMyCreatorsList] = useState([]);
    const [loadingMyCreators, setLoadingMyCreators] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [currentTab, setCurrentTab] = useState('findProfile');
    const [likedProfileTab, setLikedProfileTab] = useState('openCreators');
    const [bio, setBio] = useState('');
    const [creatorProfileDetails, setCreatorProfileDetails] = useState(null);
    const [creatorProfileDetailsYt, setCreatorProfileDetailsYt] = useState(null);

    const lastCreatorCardRef = useCallback((node) => {
        if(loadingCreators) return;
        if(observer.current) observer.current.disconnect();
        
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore){
                if (defaultSelectedKey === 'findProfile' || defaultSelectedKey === 'admirianInfluencers' || defaultSelectedKey === 'myCreators') {
                    setPage(prevPage => prevPage + 1);
                }
            }
        });
        
        if(node) observer.current.observe(node);
    }, [loadingCreators, hasMore, defaultSelectedKey]);

    // Effect for pagination
    useEffect(() => {
        if (page > 1 && (defaultSelectedKey === 'findProfile' || defaultSelectedKey === 'admirianInfluencers' || defaultSelectedKey === 'myCreators')) {
            getCreatersList(minAge, maxAge, gender, minFollowers, maxFollowers, categorySelected, location, sortBy, page);
        }
    }, [page]);

    // Combined effect for filters, tab changes and initial load
    useEffect(() => {
        const shouldFetchData = defaultSelectedKey === 'findProfile' || 
                              defaultSelectedKey === 'admirianInfluencers' || 
                              defaultSelectedKey === 'myCreators' ||
                              defaultSelectedKey === 'likedProfile';
        
        if (shouldFetchData) {
            setPage(1);
            setCreatorsList([]);
            setHasMore(true);
            setCurrentTab(defaultSelectedKey);
            getCreatersList(minAge, maxAge, gender, minFollowers, maxFollowers, categorySelected, location, sortBy, 1);
        }
    }, [defaultSelectedKey, minAge, maxAge, gender, minFollowers, maxFollowers, categorySelected, location, sortBy, platformType]);

    const getLikedUseIdList = async() => {
        const postData = {
            "filters": {},
            "return_params": "creator_uuid"
        }
        try{
            const response = await apiClient.post(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/`,
            postData)

            if(!response?.data?.error || !response?.data?.errors){
                setLikedUserUuid(response?.data?.creator_uuids)
            }
        }catch(error){
            // notification.error({message : error?.response?.data?.message})
        }
    }

    useEffect(()=>{
        getLikedUseIdList()
    },[])
    const getCategoryList = async() =>{
        const response = await apiClient.get(`${BASE_URL}/profiles/categories/`)
        if(!response?.data?.error){
            setCategoryList(response?.data)
        }
    }

    const getCategoryCount = async() => {
        const response = await apiClient.get(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/count/`)
        if(!response?.data?.error){
            const { admirian_count, open_count } = response.data;
            setCategoryCount({
                real: admirian_count,
                scraped: open_count
            })
        }
    }

    useEffect(()=>{
        getCategoryList()
        getCategoryCount()
    },[])
    useEffect(()=>{
        if(gender.length === 0){
            setGender(["All"])
        }
    },[gender])

    const buildFilters = () => {
        const filters = {};
    
        if (minFollowers) {
            filters.min_followers = minFollowers;
        }
    
        if (maxFollowers) {
            filters.max_followers = maxFollowers;
        }
    
        const shouldSkipAgeFilter = 
            defaultSelectedKey === 'findProfile' || 
            (defaultSelectedKey === 'likedProfile' && likedProfileTab === 'openCreators');
        
        if (!shouldSkipAgeFilter && minAge && maxAge) {
            filters.min_age = minAge;
            filters.max_age = maxAge;
        }
    
        if (gender) {
            filters.gender = gender.includes("All") ? ["male", "female"] : gender;
        }
    
        if (categorySelected.length > 0) {
            filters.categories = categorySelected;
        }
    
        if (location.length > 0) {
            filters.location = location;
        }
    
        if (platformType.length > 0) {
            filters.platform = platformType;
        }
    
        return filters;
    };

        const getCreatersList = async(minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy,page)=>{
            setLoadingCreators(true)
            let postData = {
                filters: buildFilters(),
                sortby: sortBy,
                page: page
            }

        try {
            let response;
            if (defaultSelectedKey === 'findProfile') {
                // Use discover-creators endpoint for Find profiles
                response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/open-creators/`, {
                    ...postData
                });
                console.log('Find profiles response with scraped_id:', response?.data?.creators?.map(creator => ({
                    creator_uuid: creator.creator_uuid,
                    scraped_id: creator.scraped_id
                })));
            } else if (defaultSelectedKey === 'admirianInfluencers') {
                // Use discover-creators endpoint with mine: false for Admirian Influencers
                console.log('Admirian Influencers request:', {
                    ...postData,
                    mine: false
                });
                response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/discover-creators/`, {
                    ...postData,
                    mine: false
                });
                console.log('Admirian Influencers response:', response?.data);
            } else if (defaultSelectedKey === 'myCreators') {
                // Use discover-creators endpoint with mine: true for My Influencers
                console.log('My Influencers request:', {
                    ...postData,
                    mine: true
                });
                response = await apiClient.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/discover-creators/`, {
                    ...postData,
                    mine: true
                });
                console.log('My Influencers response:', response?.data);
            } else if (defaultSelectedKey === 'likedProfile') {
                // Use the correct endpoint for liked creators
                console.log('Liked Profile request:', postData);
                response = await apiClient.post(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/`, postData);
                console.log('Liked Profile response:', response?.data);
            }
            
            if (!response?.data?.error) {
                if (defaultSelectedKey === 'likedProfile') {
                    const creators = response?.data?.creators || response?.data?.results || [];
                    console.log('Setting liked creators list:', creators);
                    if (page === 1) {
                        setCreatorsList(creators);
                    } else {
                        setCreatorsList(prev => [...prev, ...creators]);
                    }
                    
                    // Update hasMore based on pagination
                    const totalPages = response?.data?.total_pages || 1;
                    setHasMore(page < totalPages);
                } else {
                    // For findProfile, admirianInfluencers, and myCreators
                    const creators = response?.data?.creators || response?.data?.results || [];
                    console.log('Setting creators list:', creators);
                    if (page === 1) {
                        setCreatorsList(creators);
                    } else {
                        setCreatorsList(prev => [...prev, ...creators]);
                    }
                    
                    // Update hasMore based on pagination
                    const totalPages = response?.data?.total_pages || 1;
                    setHasMore(page < totalPages);
                }
            } else {
                console.error('API Error:', response?.data?.error);
                notification.error({
                    message: response?.data?.error?.message || 'Failed to fetch creators'
                });
            }
        } catch (error) {
            console.error('Error fetching creators:', error);
            console.error('Error response:', error?.response?.data);
            notification.error({
                message: error?.response?.data?.message || 'Failed to fetch creators. Please try again.'
            });
        } finally {
            setLoadingCreators(false);
        }
    }

    const onClose = () => {
        setDrawerVisible(false);
        setDrawerData(null);
    };

    return(
                <>
                    <Sidebar
                    defaultSelectedKey = {defaultSelectedKey}
                    setDefaultSelectedKey={setDefaultSelectedKey}
                    location = {location}
                    setLocation = {setLocation}
                    minFollowers = {minFollowers}
                    maxFollowers = {maxFollowers}
                    setMinFollowers = {setMinFollowers}
                    setMaxFollowers = {setMaxFollowers}
                    minAge = {minAge}
                    maxAge = {maxAge}
                    setMinAge = {setMinAge}
                    setMaxAge = {setMaxAge}
                    gender= {gender}
                    setGender = {setGender}
                    categoryCount ={categoryCount}
                    platformType={platformType}
                    setPlatformType={setPlatformType}
                    likedProfileTab={likedProfileTab}
                    />
                    <MasterComponentStyle>
                        {defaultSelectedKey === 'findProfile'
                        ?   
                            <FindProfile
                            categoryList = {categoryList}
                            setCategoryList = {setCategoryList}
                            categorySelected = {categorySelected}
                            setCategorySelected = {setCategorySelected}
                            sortBy = {sortBy}
                            setSortBy = {setSortBy}
                            creatorsList = {creatorsList}
                            useRef = {useRef}
                            lastCreatorCardRef ={lastCreatorCardRef}
                            loadingCreators = {loadingCreators}
                            getCategoryCount ={getCategoryCount}
                            likedUserUuid = {likedUserUuid}
                            getLikedUseIdList = {getLikedUseIdList}
                            currentTab={currentTab}
                            />
                        : defaultSelectedKey === 'likedProfile'
                        ?
                            <LikedProfile
                            sortBy = {sortBy}
                            setSortBy = {setSortBy}
                            categoryCount = {categoryCount}
                            likedUserUuid = {likedUserUuid}
                            getLikedUseIdList = {getLikedUseIdList}
                            useRef = {useRef}
                            categoryList = {categoryList}
                            categorySelected = {categorySelected}
                            setCategorySelected = {setCategorySelected}
                            filters={buildFilters()}
                            likedProfileTab={likedProfileTab}
                            setLikedProfileTab={setLikedProfileTab}
                            />
                        : defaultSelectedKey === 'admirianInfluencers'
                        ?
                            <MyCreators
                            sortBy = {sortBy}
                            setSortBy = {setSortBy}
                            getCategoryCount = {getCategoryCount}
                            likedUserUuid = {likedUserUuid}
                            getLikedUseIdList = {getLikedUseIdList}
                            myCreatorsList = {creatorsList}
                            loadingCreators = {loadingCreators}
                            lastCreatorCardRef = {lastCreatorCardRef}
                            categoryList = {categoryList}
                            categorySelected = {categorySelected}
                            setCategorySelected = {setCategorySelected}
                            />
                        :
                            <MyCreators
                            sortBy = {sortBy}
                            setSortBy = {setSortBy}
                            getCategoryCount = {getCategoryCount}
                            likedUserUuid = {likedUserUuid}
                            getLikedUseIdList = {getLikedUseIdList}
                            myCreatorsList = {creatorsList}
                            loadingCreators = {loadingCreators}
                            lastCreatorCardRef = {lastCreatorCardRef}
                            categoryList = {categoryList}
                            categorySelected = {categorySelected}
                            setCategorySelected = {setCategorySelected}
                            />
                        }        
                                    
                    </MasterComponentStyle>   
                   
                </>  
    )
}

export default DashboardBrand