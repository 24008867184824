import { Input, notification, Row, Select, Col, Tabs } from "antd";
import axios from "axios";
import apiClient from "../../../services/axiosInstance";
import React, { useEffect, useState, useRef, useCallback  } from "react";
import { parseJwtToken } from "../../../utils";
import UserDetails from "../UserDetails";
import { Container, Heading, SortByContainer } from "./style";
import { CustomButton } from "../FindProfile/style";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";

const { Option } = Select;
const { TabPane } = Tabs
const LikedProfile = ({
    categoryCount,
    sortBy,
    setSortBy,
    likedUserUuid,
    getLikedUseIdList,
    useRef,
    categoryList,
    categorySelected,
    setCategorySelected,
    filters,
    likedProfileTab,
    setLikedProfileTab
}) => {
    const observer = useRef()
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))

    const [page,setPage] = useState(1)
    const [hasMore,setHasMore] = useState(false)
    const [creatorsList, setCreatorsList] = useState([])
    const [loadingCreators, setLoadingCreators] = useState(false)
    const isFirstRender = useRef(true);
    const hasInitialDataLoaded = useRef(false);
    
    const lastCreatorCardRef = useCallback((node)=>{
        if(loadingCreators) {
            return
        }
        if(observer.current){
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore){
                setPage(prevPage => prevPage + 1)
            }
        })
        if(node){
            observer.current.observe(node)
        }
    },[loadingCreators,hasMore])

    const getLikedCreatersList = async(pageValue = 1, tab = likedProfileTab) => {
        setLoadingCreators(true)
        const postData = {
            "filters": filters || {},
            "sortby": sortBy,
            "page": pageValue
        }

        const endpoint = tab === "openCreators"
            ? `${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/open-creators/`
            : `${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/`;

        try{
            const response = await apiClient.post(endpoint, postData)
            
            if(!response?.data?.error){
                if (pageValue === 1) {
                    setCreatorsList(response?.data?.creators || []);
                } else {
                    setCreatorsList(prev => [...prev, ...(response?.data?.creators || [])]);
                }
                const totalPages = response?.data?.total_pages || 1;
                setHasMore(pageValue < totalPages);
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            setLoadingCreators(false)
        }catch(error){
            // notification.error({message : error?.response?.data?.message})
            setLoadingCreators(false)
        }
    }

    useEffect(() => {
        // Prevent multiple API calls on initial mount
        if (isFirstRender.current) {
          isFirstRender.current = false;
          getLikedCreatersList(1, likedProfileTab);
          hasInitialDataLoaded.current = true;
          return;
        }

        if (hasInitialDataLoaded.current) {
            if (page !== 1) {
              // Only for pagination
              getLikedCreatersList(page, likedProfileTab);
            } else {
              // For filter/sort/tab changes
              setCreatorsList([]);
              getLikedCreatersList(1, likedProfileTab);
            }
        }
    }, [page, sortBy, likedProfileTab, filters]);
    
    const removeItemAll = (arr, value) => {
        const newArr = [...arr]
        var i = 0;
        while (i < newArr.length) {
          if (newArr[i] === value) {
            newArr.splice(i, 1);
          } else {
            ++i;
          }
        }
        setCategorySelected(newArr)
    }

    
    return(
        <Container>
            <Tabs 
                destroyInactiveTabPane={true} 
                defaultActiveKey="openCreators" 
                activeKey={likedProfileTab} 
                onChange={(key) => {
                    setLikedProfileTab(key)
                    setPage(1)
                }}
            >
                <TabPane 
                    tab={`Open Creators${categoryCount.scraped > 0 ? ` (${categoryCount.scraped})` : ''}`}
                    key="openCreators"
                >

                    <Row style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'flex-start'}}>
                        <Col span={18} style={{display: 'flex', alignItems: 'flex-start'}}>
                            {categoryList.map(({ id, category }) => {                                     
                                return (
                                    <CustomButton
                                        isActive = {categorySelected.includes(id)}
                                        key={id}
                                        onClick={() => {
                                            categorySelected.includes(id) ? 
                                            removeItemAll(categorySelected, id) 
                                            : setCategorySelected([...categorySelected, id]) 
                                        }}
                                    >
                                        {category}
                                    </CustomButton>
                                )
                            })}
                        </Col>
                        <Col span={6}>
                            <SortByContainer>
                                <Select
                                    showArrow = {true}
                                    placeholder="Sort By"
                                    onChange={(value)=>{setSortBy(value)}}
                                    value = {sortBy}
                                >
                                    <Option value="-total_follower_count">Follower Count (Descending)</Option>
                                    <Option value="total_follower_count">Follower Count (Ascending)</Option>
                                </Select>
                            </SortByContainer>
                        </Col>
                    </Row>
                    <UserDetails
                        creatorsList={creatorsList}
                        useRef={useRef}
                        lastCreatorCardRef={lastCreatorCardRef}
                        loadingCreators={loadingCreators}
                        categoryCount={categoryCount}
                        likedUserUuid={likedUserUuid}
                        getLikedUseIdList={getLikedUseIdList}
                        currentTab='findProfile'
                    />
                </TabPane>
                
                <TabPane 
                    tab={`Admirian Creators${categoryCount.real > 0 ? ` (${categoryCount.real})` : ''}`}
                    key="admirianCreators"
                >
                    <Row style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px', alignItems: 'flex-start'}}>
                        <Col span={18} style={{display: 'flex', alignItems: 'flex-start'}}>
                            {categoryList.map(({ id, category }) => {                                     
                                return (
                                    <CustomButton
                                        isActive = {categorySelected.includes(id)}
                                        key={id}
                                        onClick={() => {
                                            categorySelected.includes(id) ? 
                                            removeItemAll(categorySelected, id) 
                                            : setCategorySelected([...categorySelected, id]) 
                                        }}
                                    >
                                        {category}
                                    </CustomButton>
                                )
                            })}
                        </Col>
                        <Col span={6}>
                            <SortByContainer>
                                <Select
                                    showArrow = {true}
                                    placeholder="Sort By"
                                    onChange={(value)=>{setSortBy(value)}}
                                    value = {sortBy}
                                >
                                    <Option value="-total_follower_count">Follower Count (Descending)</Option>
                                    <Option value="total_follower_count">Follower Count (Ascending)</Option>
                                </Select>
                            </SortByContainer>
                        </Col>
                    </Row>
                    <UserDetails
                        creatorsList={creatorsList}
                        useRef={useRef}
                        lastCreatorCardRef={lastCreatorCardRef}
                        loadingCreators={loadingCreators}
                        categoryCount={categoryCount}
                        likedUserUuid={likedUserUuid}
                        getLikedUseIdList={getLikedUseIdList}
                    />
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default LikedProfile